<template>
	<div>
		<pui-datatable
			v-if="!masterDetail"
			:modelName="modelName"
			:modelColumnDefs="modelColumnDefs"
			:externalFilter="externalFilter"
		></pui-datatable>
		<pui-datatable
			v-else
			:modelName="modelName"
			:modelColumnDefs="modelColumnDefs"
			:masterDetail="masterDetail"
			:parentModelName="parentModelName"
			:modalDialog="modalDialog"
			:showCreateBtn="showCreateBtn"
			:showDeleteBtn="showDeleteBtn"
			:externalFilter="externalFilter"
		></pui-datatable>
	</div>
</template>

<script>
import PuiGridDetailMixin from 'pui9-mixins/PuiGridDetailMixin';

export default {
	name: 'locationsequencestep-grid',
	components: {},
	mixins: [PuiGridDetailMixin],
	data() {
		return {
			modelName: 'locationsequencestep',
			modelColumnDefs: {
				pilotage: {
					render: (data, type, row, meta) => {
						if (!data) {
							return this.$t('common.no');
						}
						return this.$t('common.yes');
					}
				}
			}
		};
	}
};
</script>
